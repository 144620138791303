import { ObservationInstance, timelineObservation } from 'types/ObservationTypes';
import ObservationStats from './ObservationStats';

class EventSummaryStats {
  private observations: Array<ObservationInstance> = [];
  private observationStats: Array<ObservationStats> = [];

  public NumTeachers: number = 0;
  public NumTeachersMeetingAllBenchmarks: number = 0;
  public OnTaskBenchmarkCount: number = 0;
  public PraiseBenchmarkCount: number = 0;
  public PraiseToCorrectionBenchmarkCount: number = 0;
  public RelationshipBuildBenchmarkCount: number = 0;
  public PhysicalEnvironmentBenchmarkCount: number = 0;
  public CoorperationToCorrectionBenchmarkCount: number = 0;
  public PreventionBenchmarkCount: number = 0;
  public PraiseToCorrectionRatio: number[] = [0, 0];

  public observationMinutes: number;
  public preventions: Array<timelineObservation> = [];
  public praises: Array<timelineObservation> = [];
  public corrections: Array<timelineObservation> = [];

  // prevention stats
  public preventativePrompts: number;
  public plannedTeachings: number;
  public blendedTeachings: number;
  public preventionSkillLanguages: number;
  public preventionSpecificity: number;
  public preventionMO: number = 0;

  // praise stats
  public generalPraises: number;
  public specificPraises: number;
  public effectivePraises: number;
  public academicPraises: number;
  public behaviorPraises: number;
  public praiseSkillLanguages: number;
  public praiseSpecificity: number;
  public praiseMO: number = 0;

  // correction stats
  public correctivePrompts: number;
  public correctiveStrategies: number;
  public correctiveGuidedSelfCorrections: number;
  public correctiveTeaching: number;
  public correctiveCorrected: number;
  public correctiveNotCorrected: number;
  public correctiveSkillLanguages: number;
  public correctiveSpecificity: number;
  public correctiveMO: number = 0;

  constructor(observations: Array<ObservationInstance>) {
    this.observations = observations || [];
    this.observationStats = this.observations.map((obs) => new ObservationStats(obs));

    // there could be multiple observations for the same teacher
    // but business rules state to count each as 1 teacher
    this.NumTeachers = this.observations?.length || 0;
    this.OnTaskBenchmarkCount = this.observationStats.filter((obs) => obs.onTaskBenchmarkMet)?.length || 0;
    this.PraiseBenchmarkCount = this.observationStats.filter((obs) => obs.praiseStatementsPerMinBenchmarkMet)?.length || 0;
    this.PraiseToCorrectionBenchmarkCount = this.observationStats.filter((obs) => obs.praiseToCorrectionBenchmarkMet)?.length || 0;
    this.CoorperationToCorrectionBenchmarkCount = this.observationStats.filter((obs) => obs.cooperationToCoorectionBenchmarkMet)?.length || 0;
    this.PreventionBenchmarkCount = this.observationStats.filter((obs) => obs.preventativeStratBenchmarkMet)?.length || 0;

    this.RelationshipBuildBenchmarkCount = this.observationStats.filter((obs) => obs.relationShipBuildCountBenchmarkMet)?.length || 0;
    this.PhysicalEnvironmentBenchmarkCount = this.observationStats.filter((obs) => obs.physEnvCountCountBenchmarkMet)?.length || 0;

    const corrections = this.observationStats.reduce((acc, obs) => acc + obs.corrections.length, 0);
    const praises = this.observationStats.reduce((acc, obs) => acc + obs.praises.length, 0);
    if (corrections === 0) {
      this.PraiseToCorrectionRatio = [0, 0];
    } else {
      this.PraiseToCorrectionRatio = ObservationStats.reduceFraction(praises, corrections);
    }

    this.NumTeachersMeetingAllBenchmarks =
      this.observationStats.filter(
        (obs) =>
          obs.onTaskBenchmarkMet &&
          obs.praiseStatementsPerMinBenchmarkMet &&
          obs.praiseToCorrectionBenchmarkMet &&
          obs.cooperationToCoorectionBenchmarkMet &&
          obs.preventativeStratBenchmarkMet &&
          obs.relationShipBuildCountBenchmarkMet &&
          obs.physEnvCountCountBenchmarkMet
      )?.length || 0;

    // todo
    //this.observationMinutes = this.observationMinutesCalc();

    this.observations.forEach((obs) => {
      obs.observations
        ?.filter((item) => item.type === 'Prevention' && !item.tags.find((t) => t === 'MO'))
        .forEach((item) => {
          this.preventions.push(item);
        });
      this.preventionMO += obs.observations?.filter((item) => item.type === 'Prevention' && item.tags.find((t) => t === 'MO'))?.length || 0;
      obs.observations
        ?.filter((item) => item.type === 'Praise' && !item.tags.find((t) => t === 'MO'))
        .forEach((item) => {
          this.praises.push(item);
        });
      this.praiseMO += obs.observations?.filter((item) => item.type === 'Praise' && item.tags.find((t) => t === 'MO'))?.length || 0;
      obs.observations
        ?.filter((item) => item.type === 'Correction' && !item.tags.find((t) => t === 'MO'))
        .forEach((item) => {
          this.corrections.push(item);
        });
      this.correctiveMO += obs.observations?.filter((item) => item.type === 'Correction' && item.tags.find((t) => t === 'MO'))?.length || 0;
    });

    this.preventativePrompts = this.preventions.filter((item) => item.tags.indexOf('PP') > -1)?.length || 0;
    this.plannedTeachings = this.preventions.filter((item) => item.tags.indexOf('PT') > -1)?.length || 0;
    this.blendedTeachings = this.preventions.filter((item) => item.tags.indexOf('BT') > -1)?.length || 0;
    this.preventionSkillLanguages = this.preventions.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
    this.preventionSpecificity = this.preventions.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;

    this.generalPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('GP'))?.length > 0)?.length || 0;
    this.specificPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('SP'))?.length > 0)?.length || 0;
    this.effectivePraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('EP'))?.length > 0)?.length || 0;
    this.academicPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.endsWith('-A'))?.length > 0)?.length || 0;
    this.behaviorPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.endsWith('-B'))?.length > 0)?.length || 0;
    this.praiseSkillLanguages = this.praises.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
    this.praiseSpecificity = this.praises.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;

    this.correctivePrompts = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CP'))?.length > 0)?.length || 0;
    this.correctiveStrategies = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CS'))?.length > 0)?.length || 0;
    this.correctiveGuidedSelfCorrections =
      this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('GSC') || tag.startsWith('GCS'))?.length > 0)?.length || 0;
    this.correctiveTeaching = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CT'))?.length > 0)?.length || 0;
    this.correctiveCorrected = this.corrections.filter((item) => item.tags.filter((tag) => tag.endsWith('-BC'))?.length > 0)?.length || 0;
    this.correctiveNotCorrected = this.corrections.filter((item) => item.tags.filter((tag) => tag.endsWith('-NC'))?.length > 0)?.length || 0;
    this.correctiveSkillLanguages = this.corrections.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
    this.correctiveSpecificity = this.corrections.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;
  }
}

export default EventSummaryStats;
