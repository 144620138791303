// local imports
import { calcPercent } from 'services/numberUtils';
import EventSummaryMetricCard from './EventSummaryMetricCard';
import { Box } from '@mui/material';

const EventSummaryMetricCards = ({ eventSummaryMetrics }) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '.5rem' }}>
      <EventSummaryMetricCard
        title='On Task'
        subTitle={`${eventSummaryMetrics.OnTaskBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.OnTaskBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Praise'
        subTitle={`${eventSummaryMetrics.PraiseBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.PraiseBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Praise to Correction'
        subTitle={`${eventSummaryMetrics.PraiseToCorrectionBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.PraiseToCorrectionBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Relationship Building'
        subTitle={`${eventSummaryMetrics.RelationshipBuildBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.RelationshipBuildBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Cooperation to Correction'
        subTitle={`${eventSummaryMetrics.CoorperationToCorrectionBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.CoorperationToCorrectionBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Prevention'
        subTitle={`${eventSummaryMetrics.PreventionBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.PreventionBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
      <EventSummaryMetricCard
        title='Praise to Correction Ratio'
        subtitle='   '
        metric={`${eventSummaryMetrics.PraiseToCorrectionRatio[0]}:${eventSummaryMetrics.PraiseToCorrectionRatio[1]}`}
      />
      <EventSummaryMetricCard
        title='Physical Environment'
        subTitle={`${eventSummaryMetrics.PhysicalEnvironmentBenchmarkCount} of ${eventSummaryMetrics.NumTeachers}`}
        metric={`${calcPercent(eventSummaryMetrics.PhysicalEnvironmentBenchmarkCount, eventSummaryMetrics.NumTeachers)}%`}
      />
    </Box>
  );
};

export default EventSummaryMetricCards;
