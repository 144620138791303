import { logClientException } from 'appinsights/clientAppInsights';

export const clientSideLogout = async (session) => {
  try {
    if (window && session && session.logout_url) {
      const parsedUrl = new URL(session.logout_url);
      const allowedHosts = ['b2clogin.com'];
      if (allowedHosts.includes(parsedUrl.host)) {
        window.location.href = `${session.logout_url}?post_logout_redirect_uri=${window.location.origin}`;
      }
    }
  } catch (error) {
    logClientException(error);
  }
};
